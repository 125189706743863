import React from 'react';

function CrossIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 6.41635L17.59 5.00635L12 10.5963L6.41 5.00635L5 6.41635L10.59 12.0063L5 17.5963L6.41 19.0063L12 13.4163L17.59 19.0063L19 17.5963L13.41 12.0063L19 6.41635Z"
        fill="#6956BA"
      />
    </svg>
  );
}

export default CrossIcon;
