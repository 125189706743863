import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import {
  BORROWER_ROLE,
  CASHIER_ROLE,
  CORPORATE_ROLE,
  DEALER_ROLE,
  SUPERUSER_ROLE,
  Localization,
  MANAGER_ROLE,
} from 'constant';
import { userLoad, userLogout } from 'redux/auth/actions';
import authProvider from 'utils/authProvider';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { clearActionStatus, fetchCollection } from 'redux/crud/action';
import { AppContext, isSideBarMenuUsers } from 'helpers';

const Sidebar = ({ size, setShowSidebar }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: any) => state);
  const history = useHistory();
  const {
    showCollectionsSubmenu,
    setShowCollectionsSubmenu,
    showListingsSubmenu,
    setShowListingsSubmenu,
    showReportsSubmenu,
    setShowReportsSubmenu,
  } = useContext(AppContext);

  // Submenu toggle handler
  const toggleSubMenu = (event) => {
    switch (event?.name) {
      case t('Collections'):
        setShowCollectionsSubmenu(!showCollectionsSubmenu);
        break;
      case t('Listings'):
        setShowListingsSubmenu(!showListingsSubmenu);
        break;
      case t('Reports'):
        setShowReportsSubmenu(!showReportsSubmenu);
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    authProvider.logout();
    dispatch(clearActionStatus('userTc'));

    // clear user info and reset notifications
    dispatch(userLogout());
    dispatch(
      fetchCollection('notifications', 'cms/non-sensitive-notifications', {
        filter: {
          client: Localization.clientName,
        },
      }),
    );
    history.push('/login');
  };

  const menus = (() => {
    const collectionItems = [];
    const listingItems = [];
    const reportItems = [];

    const sidebarItems = [
      {
        name: t('Dashboard'),
        href: '/dashboard',
        link: '/dashboard',
        icon: 'dashboard.png',
      },
      {
        name: t('Collections'),
        icon: 'cashacct.png',
        items: collectionItems,
      },
    ];

    switch (auth?.user?.role) {
      case SUPERUSER_ROLE:
        return [
          {
            name: 'Broadcast',
            items: [
              {
                name: 'Add message',
                href: '/broadcast/add',
                link: '/broadcast/add',
              },
              {
                name: 'View messages',
                href: '/broadcast/view',
                link: '/broadcast/view',
              },
            ],
          },
        ];
      case DEALER_ROLE:
        return [
          {
            name: 'Accounts',
            icon: 'user.png',
            items: [
              {
                name: 'Active',
                href: '/accounts/active',
                link: '/active',
              },
              {
                name: 'Matured',
                href: '/accounts/matured',
                link: '/matured',
              },
            ],
          },
        ];
      case CASHIER_ROLE:
        return [
          {
            name: t('Accounts'),
            href: '/dashboard',
            link: '/dashboard',
          },
          {
            name: t('Collections'),
            href: '/collections',
            link: '/collections',
          },
          {
            name: t('Records'),
            href: '/transactions',
            link: '/transactions',
          },
        ];
      case MANAGER_ROLE:
        collectionItems.push({
          name: t(Localization.branch),
          href: '/branches',
          link: '/branches',
        });
        collectionItems.push({
          name: t(Localization.collectionSpecialist),
          href: '/specialist',
          link: '/specialist',
        });
        if (Localization.showCashierPage) {
          collectionItems.push({
            name: t('Cashiers'),
            href: '/cashiers',
            link: '/cashiers',
          });
        }
        collectionItems.push({
          name: t('Accounts'),
          href: '/accounts',
          link: '/accounts',
        });

        listingItems.push({
          name: t('Users'),
          href: '/users',
          link: '/users',
        });

        reportItems.push({
          name: t('Daily Active Collectors'),
          href: '/daily-active-collectors',
          link: '/daily-active-collectors',
        });

        reportItems.push(
          {
            name: t('Monthly Active Collectors'),
            href: '/monthly-active-collectors',
            link: '/monthly-active-collectors',
          },
          {
            name: t('Daily Collections'),
            href: '/daily-collections',
            link: '/daily-collections',
          },
          {
            name: t('Downloads'),
            href: '/reports-downloads',
            link: '/reports-downloads',
          },
        );

        sidebarItems.push({
          name: t('Listings'),
          icon: 'listings.png',
          items: listingItems,
        });

        sidebarItems.push({
          name: t('Reports'),
          icon: 'icon_report.png',
          items: reportItems,
        });

        return sidebarItems;
      default:
        return [];
    }
  })();

  const renderNavMenu = (el: any) => {
    return (
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {el?.icon && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <img
              className="align-self-center"
              src={`${process.env.PUBLIC_URL}/images/${el.icon}`}
              alt="icon"
              width="28"
              height="28"
              onClick={() => toggleSubMenu(el)}
            />
          )}
          {!el?.link ? (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div className="ml-3 align-self-center" onClick={() => toggleSubMenu(el)} aria-hidden="true">
              {el.name}
            </div>
          ) : (
            <Button
              color="text"
              onClick={(event) => {
                event.stopPropagation();
                if (['md', 's', 'xs'].includes(size.screen)) setShowSidebar(false);
                if (history.location.pathname !== el.link) {
                  history.push(el.link);
                }
              }}
              style={{ textAlign: 'left' }}
            >
              {el.name}
            </Button>
          )}
        </div>
        {el?.items &&
          ((el?.name === t('Collections') && showCollectionsSubmenu) ||
          (el?.name === t('Listings') && showListingsSubmenu) ||
          (el?.name === t('Reports') && showReportsSubmenu) ? (
            <div className="table-arrow-up align-self-center" onClick={() => toggleSubMenu(el)} aria-hidden="true" />
          ) : (
            <div className="table-arrow-down align-self-center" onClick={() => toggleSubMenu(el)} aria-hidden="true" />
          ))}
      </div>
    );
  };

  const route = useRouteMatch();

  const activeRoute = route.path === '/' ? '/dashboard' : route.path;

  return (
    <SidebarComponent className="p-0 d-flex flex-column justify-content-between">
      <div style={{ textAlign: 'center', overflowY: 'auto' }}>
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
            alt={Localization.clientName}
            // height="45"
            width="80%"
            className="mt-3"
          />
        </Link>
        <ListGroupStyle>
          {menus.map((el: any) => (
            <>
              <ListGroupItem
                tag={el.href ? 'a' : 'div'}
                key={el.name}
                className={`parent ${activeRoute.includes(el.link) ? 'is-active' : ''}`}
              >
                {renderNavMenu(el)}
                {el?.items &&
                  ((el?.name === t('Collections') && showCollectionsSubmenu) ||
                    (el?.name === t('Listings') && showListingsSubmenu) ||
                    (el?.name === t('Reports') && showReportsSubmenu)) && (
                    <ListGroupStyle>
                      {el?.items?.map((child: any, index: number) => (
                        <ListGroupItem
                          key={child.href}
                          className={`sub ${activeRoute === child.link ? 'is-active' : ''}`}
                        >
                          <ul style={{ paddingLeft: '20px' }}>
                            <li
                              style={{
                                paddingTop: '12px',
                                paddingBottom: '12px',
                                listStyle: 'disc',
                                color: '#999999',
                              }}
                            >
                              {renderNavMenu(child)}
                            </li>
                          </ul>
                        </ListGroupItem>
                      ))}
                    </ListGroupStyle>
                    // eslint-disable-next-line @typescript-eslint/indent
                  )}
              </ListGroupItem>
            </>
          ))}
        </ListGroupStyle>
      </div>
      <ListGroupStyle>
        <div>
          <div className="d-flex justify-content-center mb-2">
            <FooterText className="mr-2">Powered by</FooterText>
            <img
              className="d-flex align-self-start"
              src={`${process.env.PUBLIC_URL}/images/TVSDigital_logo_72x26.svg`}
              alt="TVS Digital"
            />
          </div>
        </div>
        <Hrline />
        <div className="d-flex justify-content-around mb-3 ml-3 mr-3">
          <LegalLink href={Localization.PRIVACY_POLICY_URL} target="_blank">
            Privacy Policy
          </LegalLink>
          <LegalLink href={Localization.TERMS_OF_USE_URL} target="_blank">
            Terms of use
          </LegalLink>
          <LegalLink href={Localization.COOKIE_POLICY_URL} target="_blank">
            Cookie Policy
          </LegalLink>
        </div>
      </ListGroupStyle>
    </SidebarComponent>
  );
};
const Hrline = styled.hr`
  border: 0;
  border-bottom: 1px solid #dddddd;
  display: block;
  width: 90%;
`;
const SidebarComponent = styled.div`
  box-shadow: 0;
  background-color: #fff;
  width: 250px;
  position: fixed;
  height: calc(100vh - 50px);
  @media (max-width: 820px) {
    position: static;
    width: 100%;
    flex: 0 0 100%;
  }
`;
const ListGroupStyle = styled(ListGroup)`
  margin-top: 20px;
  .list-group {
    margin: 0;
  }
  .list-group-item {
    background-color: transparent;
    padding: 0;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid transparent;
    margin-left: -1px;
    .btn {
      font-weight: 500;
      color: #333333;
    }
    &.sub {
      padding-left: 27px;
      .btn {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
      &.is-active {
        a,
        .btn {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }
        border-radius: 0px 32px 32px 0px;
        background-color: #e4e4e4;
        margin-right: 8px;
      }
    }
    > div {
      padding: 15px;
      font-weight: 500;
      color: #333333;
      align-self: center;
    }
    &.parent.is-active {
      > div {
        border-radius: 0px 32px 32px 0px;
        background-color: #e4e4e4;
        margin-right: 8px;
      }
    }
  }
`;
const FooterText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #333333;
`;
const LegalLink = styled.a`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
`;
export default Sidebar;
