import React from 'react';
import { useTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';

import { PaginationProps } from 'reactstrap';
import styled from 'styled-components';

export type TvsTableProps = {
  totalSize: number;
  sizePerPage: number;
  columns: any[];
  data: any[];
  page: number;
  onTableChange: (type: string, status: any) => void;
  keyField?: string;
  loading?: boolean;
  paginateTable?: boolean;
  rowStyle?: any;
};

const TvsTable = ({
  data,
  totalSize,
  sizePerPage,
  columns,
  page,
  onTableChange,
  keyField,
  loading,
  paginateTable = true,
  rowStyle,
}: TvsTableProps) => {
  const { t } = useTranslation();
  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: t('First'),
    prePageText: <div className="table-arrow-left" />,
    nextPageText: 'Next',
    lastPageText: t('Last'),
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    totalSize,
    sizePerPage,
  };

  const rowStyleDefault = (row: any, rowIndex: number) => {
    const style = {
      color: '#5A6168',
    };
    // if (rowIndex % 2 === 0) {
    //   return {
    //     background: 'rgba(42, 42, 62, 0.04)',
    //     ...style,
    //   };
    // }
    return {
      background: '#fff',
      ...style,
    };
  };

  if (paginateTable) {
    return (
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }: PaginationProps) => {
          // eslint-disable-next-line no-param-reassign
          paginationProps.dataSize = totalSize;
          // eslint-disable-next-line no-param-reassign
          paginationProps.page = page;

          return (
            <ToolkitProvider keyField={keyField || 'id'} columns={columns} data={data} search>
              {(toolkitprops) => (
                <>
                  <BootstrapTable
                    bordered={false}
                    {...toolkitprops.baseProps}
                    {...paginationTableProps}
                    remote={{ filter: true, sort: true, pagination: true }}
                    onTableChange={onTableChange}
                    noDataIndication={loading ? t('Loading') : t('No result')}
                    rowStyle={rowStyle || rowStyleDefault}
                    loading={loading}
                    overlay={overlayFactory({ background: '#fbfbfc' })}
                  />

                  <div className="d-flex justify-content-center table-pagination my-5">
                    <PaginationListStandalone {...paginationProps} className="d-inline mr-auto" />
                  </div>
                </>
              )}
            </ToolkitProvider>
          );
        }}
      </PaginationProvider>
    );
  }
  return (
    <ToolkitProvider keyField={keyField || 'id'} columns={columns} data={data} search>
      {(toolkitprops) => (
        <>
          <BootstrapTable
            bordered={false}
            {...toolkitprops.baseProps}
            remote={{ filter: true, sort: true, pagination: true }}
            onTableChange={onTableChange}
            noDataIndication={loading ? t('Loading') : t('No result')}
            rowStyle={rowStyle}
            overlay={overlayFactory({ background: '#fbfbfc' })}
          />
        </>
      )}
    </ToolkitProvider>
  );
};

export default TvsTable;

export const sortCaret = (column: any, sort: any) => {
  const IStyled = styled.i`
    display: inline-block;
    margin-left: 10px;
    &.unsort {
      border: solid #e5e5e5;
      border-width: 0 2px 2px 0;
      padding: 3px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      margin-bottom: 2px;
    }
    &.up {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-bottom: 5px solid #5a6168;
      margin-bottom: 2px;
    }
    &.down {
      margin-bottom: 2px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-top: 5px solid #5a6168;
    }
  `;

  if (!sort || sort.s !== column.dataField)
    return <img src={`${process.env.PUBLIC_URL}/images/unsort.png`} alt="unsort" width={20} height={20} />;
  if (sort.o === 'asc') return <img src={`${process.env.PUBLIC_URL}/images/sort-asc.png`} alt="sort-asc" height={18} />;
  if (sort.o === 'desc')
    return <img src={`${process.env.PUBLIC_URL}/images/sort-desc.png`} alt="sort-desc" height={18} />;
  return null;
};
