/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { AppContext, translateDateString } from 'helpers';
import { NEPAL_TZ } from 'constant';
import moment from 'moment';
import NepaliDate from 'nepali-date';
import Calendar from 'components/icons/calendar';
import SmallCalendar from 'components/icons/smallCalendar';
import { Localization } from '../../constant/config';

interface Props {
  handleChange?: (m: string, y: string) => void;
}

const MonthDropdown = ({ handleChange }: Props) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const {
    month,
    year,
    currentMonth,
    currentYear,
    timezone,
    monthList,
    setCollectionCycle,
    collectionCycle,
    setMonth,
    setYear,
  } = useContext(AppContext);
  const authData = JSON.parse(localStorage.getItem('tvs.token_data') || '{}');

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [active, setActive] = useState<any>({
    monthIndex: null,
    year: null,
  });

  const currMonth =
    timezone === NEPAL_TZ
      ? new NepaliDate(currentYear, currentMonth, 1).format('MM') - 1
      : currentMonth || +moment().format('MM') - 1;

  const currYear =
    timezone === NEPAL_TZ
      ? new NepaliDate(currentYear, currentMonth, 1).format('YYYY')
      : currentYear || +moment().format('YYYY');

  const list = [...Array(12)].map((_, i) => {
    let m = '';
    let loop = i;

    if (Localization.clientName === 'Wheeltek') {
      loop -= 1;
    }

    if (+currMonth - loop > 11) {
      m = `${monthList[0]} ${+currentYear + 1}`;
    } else if (+currMonth - loop >= 0) {
      m = `${monthList[+currentMonth - loop]} ${+currentYear}`;
    } else {
      m = `${monthList[12 + (+currMonth - loop)]} ${+currYear - 1}`;
    }
    return m;
  });

  useEffect(() => {
    setActive({
      monthIndex: month,
      year,
    });
  }, [month, year]);

  const handleSelectItem = (m: string, y: any) => {
    let finding = monthList.findIndex((item: string) => item === m);
    let yearVar = y;

    if (finding > -1 && Localization.clientName === 'Wheeltek') {
      finding -= 1;

      if (finding < 0) {
        finding = 11;
        yearVar = Number(yearVar) - 1;
      }
    }

    if (finding > -1) {
      setActive({
        monthIndex: finding,
        year: yearVar,
      });
      if (handleChange) {
        handleChange(finding.toString(), yearVar);
      }

      const updateCollectionCycle = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/v1/system-date?month=${finding}&year=${yearVar}`,
          );

          const result = await response.json();

          if (result?.data?.first) {
            setCollectionCycle({
              from: result?.data?.first,
              to: result?.data?.last,
              month: result?.data?.month_index,
              year: result?.data?.year,
            });

            setMonth(String(result?.data?.month_index));
            setYear(String(result?.data?.year));
          }
        } catch (err) {
          console.log(err);
        }
      };

      updateCollectionCycle();
    }
  };

  const renderItem = (item: any) => {
    const st = item.split(' ');
    return (
      <DropdownItemStyle
        key={item}
        onClick={() => {
          handleSelectItem(st[0], st[1]);
        }}
        // className={+active.monthIndex === indx ? 'active' : ''}
      >
        {t(item)}
      </DropdownItemStyle>
    );
  };

  const renderMonthYear = () => {
    let { monthIndex, year: yearVar } = active;

    if (Localization.clientName === 'Wheeltek') {
      monthIndex = Number(monthIndex) + 1;

      if (monthIndex > 11) {
        monthIndex = 0;
        yearVar = Number(yearVar) + 1;
      }
    }

    return `${monthList[monthIndex]} ${yearVar}`;
  };

  return (
    <div>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        style={{
          border: '1px solid #6956BA',
          height: '56px',
          borderRadius: '8px',
          background: '#fff',
          width: 'max-content',
          marginBottom: '5px',
        }}
      >
        <DropdownToggleStyle caret color="x" className="p-3 border-0 justify-content-between">
          {t(active ? renderMonthYear() : '')}
        </DropdownToggleStyle>
        <DropdownMenu className="mt-2">{list && list.map((item: any) => renderItem(item))}</DropdownMenu>
      </Dropdown>
      {collectionCycle?.from && (
        <div style={{ fontStyle: 'italic', position: 'absolute' }}>
          <SmallCalendar />
          <span
            style={{
              marginLeft: '5px',
            }}
          >
            {`${translateDateString(moment(collectionCycle?.from).format("DD MMM 'YY"))} to ${translateDateString(
              moment(collectionCycle?.to).format("DD MMM 'YY"),
            )}`}
          </span>
        </div>
      )}
    </div>
  );
};

export const DropdownItemStyle = styled(DropdownItem)`
  &.active {
    background: #ffcc4d;
  }
`;

export const DropdownToggleStyle = styled(DropdownToggle)`
  &.btn {
    color: #263238;
    font-size: 16px;
    font-weight: 400;
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
`;

export default MonthDropdown;
