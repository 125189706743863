import React from 'react';

function TickIcon() {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.29508 10.8814L2.12508 6.71139L0.705078 8.12139L6.29508 13.7114L18.2951 1.71139L16.8851 0.301392L6.29508 10.8814Z"
        fill="white"
      />
    </svg>
  );
}

export default TickIcon;
