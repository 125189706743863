import React from 'react';

function GreenCircle() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM5.103 10.003L2.59 7.49C2.317 7.217 2.317 6.776 2.59 6.503C2.863 6.23 3.304 6.23 3.577 6.503L5.6 8.519L10.416 3.703C10.689 3.43 11.13 3.43 11.403 3.703C11.676 3.976 11.676 4.417 11.403 4.69L6.09 10.003C5.824 10.276 5.376 10.276 5.103 10.003Z"
        fill="#15A683"
      />
      <path
        d="M2.53879 7.51188L5.06446 10.0376C5.33884 10.3119 5.7891 10.3119 6.05644 10.0376L11.3962 4.69776C11.6706 4.42338 11.6706 3.98016 11.3962 3.70578C11.1219 3.43141 10.6786 3.43141 10.4043 3.70578L5.56397 8.54607L3.53077 6.5199C3.25639 6.24553 2.81317 6.24553 2.53879 6.5199C2.26441 6.79428 2.26441 7.2375 2.53879 7.51188Z"
        fill="white"
      />
    </svg>
  );
}

export default GreenCircle;
