/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form, Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import { RecordPaymentForm } from 'components/tvs-components/record-payment';
import { RecordPaymentContext, recordPaymentDefaultValue } from 'helpers';
import { formatCurrency, PARTIAL_PAYMENT, PROMISE_TO_PAY } from 'constant';
import { validate, custValidate } from 'helpers/validate';
import { useDispatch, useSelector } from 'react-redux';
import { clearActionStatus, createRecord, fetchRecord } from 'redux/crud/action';
import { State } from 'redux/types';
import AddressContactRecordPayment from 'components/tvs-components/record-payment/sidebar';
import { useHistory, useLocation, useRouteMatch, withRouter } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { Localization } from 'constant/config';
import moment from 'moment';
import { TvsIconInfo } from 'components/icons';
import Analytics from 'components/web-services/firebase';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const RecordPayment = ({ match }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const formikRef = useRef(null) as any;
  const currency = Localization.currencyAbbrev;
  const [editableAddress, setEditableAddress] = useState(false);
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [isDetailLoading, setDetailLoading] = useState(false);
  const { crudState, auth } = useSelector((st: State) => st);
  const [state, setState] = useState<any>(recordPaymentDefaultValue);
  const [data, setData] = useState<any>({});
  const toggleOtp = () => setShowOtp(!showOtp);
  const toogleAddress = () => setEditableAddress(!editableAddress);
  const route = useRouteMatch<any>();
  const toastrOptions = {
    timeOut: 6000,
    closeOnToastrClick: true,
  };
  // const { timezone } = useContext(AppContext);
  const [custLocation, setCustLocation] = useState({
    address: '',
    city: '',
    province: '',
    zipcode: '',
  });
  const [paid, setPaid] = useState(0);
  const [expectedAmt, setExpected] = useState(0);
  const [paymentDate, setPaymentDate] = useState(moment(new Date()).format('MM/DD/YYYY'));
  const [isAdvancePayment, setIsAdvancePayment] = useState(false);
  const [isPtpFlow, setIsPtpFlow] = useState(false);
  const [numInstalments, setNumInstalments] = useState('1');
  const query = useQuery();

  useEffect(() => {
    if (query.get('button') === 'ptp') {
      setIsPtpFlow(true);
      if (Analytics.analyticsEnabled) {
        Analytics.logAnalyticsEvent(
          Analytics.EVENT_TYPE.cashier.recordPTPVisited,
          { user_city: auth?.user?.city, user_branch: auth?.user?.branch },
          { page_title: Analytics.PAGES.cashier.recordPTPPage, page_location: window.location.href },
        );
      }
    } else {
      setIsPtpFlow(false);
      if (Analytics.analyticsEnabled) {
        Analytics.logAnalyticsEvent(
          Analytics.EVENT_TYPE.cashier.recordPaymentVisited,
          { user_city: auth?.user?.city, user_branch: auth?.user?.branch },
          { page_title: Analytics.PAGES.cashier.recordPaymentPage, page_location: window.location.href },
        );
      }
    }
  }, []);

  useEffect(() => {
    if (isPtpFlow) {
      recordPaymentDefaultValue.payment_note = PROMISE_TO_PAY;
      setState(recordPaymentDefaultValue);
    } else {
      recordPaymentDefaultValue.payment_note = PARTIAL_PAYMENT;
      setState(recordPaymentDefaultValue);
    }
  }, [isPtpFlow]);

  useEffect(() => {
    dispatch(
      fetchRecord('cashierDetail', 'cms/ch-listing', +match?.params?.id, {
        payment_date: paymentDate,
        num_instalments: numInstalments,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params?.id]);

  useEffect(() => {
    dispatch(fetchRecord('total_expected', 'collection/payment-detail', route?.params?.id));
    if (!crudState?.startup?.byId?.payload) dispatch(fetchRecord('startup', 'startup'));
  }, []);

  // useEffect(() => {
  //   if (crudState?.total_expected?.byId) {
  //     const { payload, success, error } = crudState?.total_expected?.byId;
  //     if (success) {
  //       setExpected(payload?.total_expected_amount);
  //       setPaid(payload?.total_collected_amount);
  //     }
  //     if (error) {
  //       toastr.error('', 'Can not get history payment.');
  //     }
  //   }
  // }, [crudState?.total_expected]);

  useEffect(() => {
    formikRef?.current?.setSubmitting(crudState?.payment?.actionStatus?.create?.pending || false);
    if (crudState?.payment?.actionStatus) {
      const { create } = crudState?.payment?.actionStatus;
      if (create?.success) {
        history.push('/');
        toastr.success(t('Payment record successfully'), '');
        dispatch(clearActionStatus('payment'));
      } else if (create?.error) {
        toastr.error('', t(create?.error));
        dispatch(clearActionStatus('payment'));
      }
    }
  }, [crudState.payment]);

  useEffect(() => {
    formikRef?.current?.setSubmitting(crudState?.paymentOtp?.actionStatus?.create?.pending || false);
    if (crudState?.paymentOtp?.actionStatus?.create?.success) {
      setShowOtp(true);
    } else if (crudState?.paymentOtp?.actionStatus?.create?.success === false) {
      toastr.error(
        t('Can not submit record payment. Contact Administrator for more detail'),
        crudState?.paymentOtp?.actionStatus?.create?.error,
        toastrOptions,
      );
      dispatch(clearActionStatus('paymentOtp'));
    }
    if (crudState?.paymentOtp?.actionStatus?.create?.success) {
      dispatch(clearActionStatus('paymentOtp'));
    }
  }, [crudState.paymentOtp]);

  const sendOtp = (
    country_code: string,
    cust_phone: string,
    attempts: number | undefined,
    payment_note: string | undefined,
    is_ptp: boolean | undefined,
    cash_received: string | undefined,
    penalty_received: string | undefined,
    ptp_amount: string | undefined,
    ptp_date: string | undefined,
  ) => {
    if (isPtpFlow) {
      is_ptp = true;
      cash_received = '0.00';
      penalty_received = '0.00';
    }

    dispatch(
      createRecord('paymentOtp', 'cms/payment_otp', {
        rid: data?.id,
        country_code,
        cust_phone,
        uuid: '',
        attempts,
        payment_note,
        is_ptp,
        cash_received,
        penalty_received,
        ptp_amount,
        ptp_date,
      }),
    );
  };

  const makeupPostData = (values: any) => {
    const fieldNotSend = [
      'is_different_address',
      'total_expected',
      'penalty_min',
      'is_not_provide_phone',
      'is_eligible_ppd',
    ];
    const fieldNeedConvertToNumber = [
      'cash_received',
      'principal_payment',
      'penalty_payment',
      'principal_payment',
      'penalty_payments_received',
      'principal_payments_received',
      'ptp_amount',
      'ppd_amount',
    ];

    const postData: any = { ...values };

    if (values.is_not_provide_phone) {
      delete postData.country_code;
      delete postData.cust_phone;
      delete postData.phone_number_relationship;
    }

    if (isPtpFlow) {
      postData.is_ptp = true;
    }

    Object.keys(postData).forEach((key: any) => {
      if (fieldNotSend.includes(key) || postData[key] === '') {
        delete postData[key];
      }
      if (fieldNeedConvertToNumber.includes(key)) {
        postData[key] = values[key] ? values[key].toString().split(',').join('') : 0;
      }
      if (isPtpFlow && (key === 'principal_payment' || key === 'penalty_payment' || key === 'cash_received')) {
        postData[key] = '0.00';
      }
      if (key === 'principal_payment' || key === 'penalty_payment') {
        postData[key === 'principal_payment' ? 'principal_received' : 'penalty_received'] = postData[key];
        delete postData[key];
      }
    });
    return postData;
  };
  const submit = async (values: any) => {
    if (values.cust_phone && values.country_code && !showOtp && !values.is_not_provide_phone) {
      sendOtp(
        values.country_code,
        values.cust_phone,
        1,
        values.payment_note,
        values.is_ptp,
        values.cash_received,
        '0',
        values.ptp_amount,
        values.ptp_date,
      );
    } else {
      const postData = makeupPostData(values);
      dispatch(createRecord('payment', 'cms/payment', postData));
    }
  };

  function capitalizeTheFirstLetterOfEachWord(words: string) {
    if (words) {
      const separateWord = words.toLowerCase().split(' ');
      for (let i = 0; i < separateWord.length; i++) {
        separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
      }
      return separateWord.join(' ');
    }
    return '';
  }

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setDetailLoading(false);
    }
  }, [data]);

  useEffect(() => {
    const pending = crudState?.cashierDetail?.byId?.pending;
    setDetailLoading(true);
    if (!pending) {
      setData(crudState?.cashierDetail?.byId?.payload);
      const tempState = { ...state };
      if (crudState?.cashierDetail?.byId?.payload) {
        const { payload } = crudState?.cashierDetail?.byId;
        setCustLocation({
          ...payload?.customer,
          province: capitalizeTheFirstLetterOfEachWord(payload?.customer?.province),
          city: capitalizeTheFirstLetterOfEachWord(payload?.customer?.city),
        });
        const {
          acct_number,
          due,
          customer,
          penalty_received,
          principal_received,
          ppd_received,
          is_advance_payment,
          total_expected,
          total_collected,
          num_instalments,
        } = payload;
        setIsAdvancePayment(is_advance_payment);
        // const totalExpected = +calculateTotal(due, +penalty_received, +principal_received, +ppd_received);
        setExpected(total_expected);
        setPaid(total_collected);
        let ppdAmt = 0;

        if (payload?.ppd_amount && payload?.is_eligible_ppd && +total_expected > 0) {
          ppdAmt = +total_expected < +payload?.ppd_amount ? total_expected : payload?.ppd_amount;
        }

        const cashReceived = +(total_expected - ppdAmt).toFixed(2);
        const penaltyActual = cashReceived < ppdAmt ? 0 : +(+due.penalty_due - +penalty_received).toFixed(2);

        const principleActual = (cashReceived - penaltyActual).toFixed(2);

        Object.assign(tempState, {
          rid: route?.params?.id,
          acct_number,
          due_id: due.id,
          customer_id: customer.id,
          cust_phone: payload?.customer?.phone_number,
          country_code: payload?.customer?.country_code,
          total_expected,
          penalty_due: +due.penalty_due,
          penalty_min: +due.penalty_min,
          penalty_payments_received: penalty_received,
          principal_due: +due.principal_due,
          principal_payments_received: principal_received,
          ppd_received: formatCurrency(ppd_received),
          ppd_amount: formatCurrency(ppdAmt),
          unable_pay_more: false,
          is_eligible_ppd: payload.is_eligible_ppd,
          penalty_actual: penaltyActual,
          principal_actual: principleActual,
          payment_date: paymentDate,
          num_instalments,
          last_payment_id: payload?.payment?.id,
        });
      }
      setState(tempState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crudState?.cashierDetail]);

  useEffect(() => {
    if (crudState?.customer?.actionStatus?.create) {
      const { pending, success, error } = crudState?.customer?.actionStatus?.create;
      setLoading(pending || false);
      if (success) {
        setEditableAddress(false);
        toastr.success('', t('Customer address is updated successfully'));
        dispatch(clearActionStatus('customer'));
      } else if (error) {
        toastr.error(t('Can not save data'), error);
        dispatch(clearActionStatus('customer'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crudState?.customer]);

  const handleSaveAddress = (values: any) => {
    setData({
      ...data,
      customer: {
        ...data.customer,
        address: values.address,
        city: values.city,
        province: values.province,
        zipcode: values.zipcode,
      },
    });

    setCustLocation({
      address: values.address,
      city: values.city,
      province: values.province,
      zipcode: values.zipcode,
    });

    const postData = {
      rid: match.params.id,
      uuid: '',
      city: values.city,
      province: values.province,
      zipcode: values.zipcode,
      cust_address2: values.address,
    };
    dispatch(createRecord('customer', 'cms/payment/update-customer-address', postData));
  };

  const customerValidate = (values: any) => {
    const errors = custValidate(values, crudState?.startup?.byId?.payload?.cities);
    return errors;
  };

  const paymentDetailsChange = (value: any, calledOnBlur: boolean = false) => {
    if (!value) return;

    // if (!value.payment_date || !value.num_instalments) return;
    if (!value.payment_date) return;

    // if (value.payment_date === paymentDate && value.num_instalments === numInstalments) return;
    if (value.payment_date === paymentDate && !calledOnBlur) return;
    setPaymentDate(value.payment_date);
    // setNumInstalments(value.num_instalments);
    const cashReceived = +value.cash_received.replace(/,/gi, '');
    if (!isNaN(cashReceived)) {
      dispatch(
        fetchRecord('cashierDetail', 'cms/ch-listing', +match?.params?.id, {
          payment_date: value.payment_date,
          cash_received: cashReceived,
        }),
      );
    }
  };

  return (
    <RecordPaymentContext.Provider
      value={{
        showOtp,
        currency,
        toggleOtp,
        loading,
        sendOtp,
        paid,
        totalExpected: expectedAmt,
      }}
    >
      <Container style={{ paddingBottom: '100px' }}>
        <div className="pt-4 pb-2 ">
          <h1 className="mb-3">{query.get('button') === 'ptp' ? t('Record Promise To Pay') : t('Record Payment')} </h1>
          {!isAdvancePayment && (
            <div className="d-flex flex-row align-items-start mb-4">
              <TvsIconInfo className="mr-1" size="2em" />
              <div>{t('To record an advanced payment, please submit a full payment record first.')}</div>
            </div>
          )}
        </div>

        <div>
          <Row>
            <Col lg={4}>
              <Formik
                initialValues={custLocation}
                enableReinitialize={true}
                onSubmit={handleSaveAddress}
                validate={customerValidate}
              >
                {({ handleSubmit, errors, ...rest }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <AddressContactRecordPayment
                      isAdvancePayment={isAdvancePayment}
                      toogleAddress={toogleAddress}
                      editableAddress={editableAddress}
                      setEditableAddress={setEditableAddress}
                      data={data}
                    />
                  </Form>
                )}
              </Formik>
            </Col>
            <Col lg={8}>
              <Formik
                innerRef={(instance) => {
                  formikRef.current = instance;
                }}
                initialValues={state}
                enableReinitialize
                onSubmit={submit}
                validate={(values) => validate(values, { ...data, currency })}
              >
                {({ handleSubmit, errors, ...rest }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <RecordPaymentForm
                      isLoading={isDetailLoading}
                      onPaymentDetailChange={paymentDetailsChange}
                      isEligible={data?.is_eligible_ppd}
                      ppdAmount={data?.ppd_amount}
                      ppdMsg={data?.ppd_msg}
                      isAdvancePayment={data?.is_advance_payment}
                      instalmentsLeft={data?.instalments_left}
                      expectedCurrentDueAfterPayment={data?.expected_current_due_after_payment}
                      expectedTotalOutstandingDueAfterPayment={data?.expected_total_outstanding_due_after_payment}
                      custLocation={custLocation}
                      isPtp={isPtpFlow}
                      data={data}
                    />
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </Container>
    </RecordPaymentContext.Provider>
  );
};

export default withRouter(RecordPayment);

export const calculateTotal = (
  due: any,
  penalty_received: number,
  principal_received: number,
  ppd_received: number,
) => {
  let d: number = +due.penalty_due + +due.principal_due;
  if (penalty_received) {
    d -= penalty_received;
  }
  if (principal_received) {
    d -= principal_received;
  }
  if (ppd_received) {
    d -= ppd_received;
  }
  return d.toFixed(2) === '-0.00' ? '0.00' : d.toFixed(2);
};
