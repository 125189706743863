import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Button, Card, CardBody, Col, FormGroup } from 'reactstrap';
import { useFormikContext } from 'formik';
import { Input, Datepicker } from 'components/form';
import { FULL_PAYMENT, PARTIAL_PAYMENT, PROMISE_TO_PAY, NEPAL_TZ, formatCurrency } from 'constant';
import { Localization } from 'constant/config';
import { AppContext, RecordPaymentContext } from 'helpers';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { State } from 'redux/types';
import NepaliDate from 'nepali-date';
import { ZendeskAPI } from 'react-zendesk';
import styles from 'styled-components';
import PtpRecordPayment from './ptp';
import ContactRecordPayment from './contact';
import ReceiptRecordPayment from './receipt';
import OtpPayment from './otp';
import PPDBox from './ppd';
import VisitStep from './visit';
import CashReceivedStep from './cash-received';

const PaymentForm = ({
  isLoading,
  isEligible,
  ppdAmount,
  onPaymentDetailChange,
  ppdMsg,
  isAdvancePayment,
  instalmentsLeft,
  expectedCurrentDueAfterPayment,
  expectedTotalOutstandingDueAfterPayment,
  custLocation,
  isPtp,
  data,
}: any) => {
  // const location = useLocation();
  const history = useHistory();
  const { collectionCycle } = useContext(AppContext);
  const { values, setValues, isSubmitting } = useFormikContext<any>();
  const { auth } = useSelector((state: State) => state);
  const { t } = useTranslation();

  useEffect(() => {
    if (!values.is_ptp) {
      setValues({
        ...values,
        ptp_amount: '',
        ptp_date: '',
        ptp_location: '',
        ptp_collection_address: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.is_ptp]);

  useEffect(() => {
    onPaymentDetailChange(values);
  }, [values.payment_date]);

  // useEffect(() => {
  //   // (window as any).zE('webWidget', 'hide');
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.async = true;
  //   script.innerHTML = `window.zESettings = {
  //     webWidget: {
  //       offset: {
  //         vertical: '80px',
  //       }
  //     }
  //   };`;
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    let cash;
    if (+values.total_expected - +ppdAmount < 0) {
      cash = 0;
    } else {
      cash = (+values.total_expected - +ppdAmount).toFixed(2);
    }

    setValues({
      ...values,
      payment_note: isPtp ? PROMISE_TO_PAY : values.payment_note,
      ppd_amount: isPtp ? '0.00' : formatCurrency(ppdAmount),
      is_different_address: false,
      unable_pay_more: false,
      is_ptp: isPtp,
    });
  }, [isAdvancePayment, values.total_expected]);

  const otpModal = <OtpPayment />;

  const getMonthStart = () => {
    if (auth?.settings?.timezone === NEPAL_TZ) {
      const today = new NepaliDate();
      return new NepaliDate(today.getYear(), today.getMonth(), 1).getEnglishDate();
    }
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  };

  return isLoading ? (
    <div>{t('Loading')}...</div>
  ) : (
    <>
      <Card className="round-0">
        <CardBody className="p-0">
          <div style={{ background: '#D9D9D9' }} className="pt-4 px-4 pb-2">
            <FormGroup row>
              <Col lg={6}>
                <Datepicker
                  id="payment_date"
                  placeholder={t('Select Date')}
                  label={t('Payment Date')}
                  maxDate={new Date(collectionCycle?.to)}
                  minDate={new Date(collectionCycle?.from)}
                />
              </Col>
              <Col lg={6}>
                {Localization.activatePPD && (
                  <PPDBox payment_note={values.payment_note} isEligible={isEligible} ppdMsg={t(ppdMsg)} />
                )}
              </Col>
            </FormGroup>
          </div>
          {(values.payment_note === FULL_PAYMENT || values.payment_note === PARTIAL_PAYMENT) && (
            <CashReceivedStep
              isAdvancePayment={isAdvancePayment}
              onPaymentDetailChange={onPaymentDetailChange}
              instalmentsLeft={instalmentsLeft}
              expectedCurrentDueAfterPayment={expectedCurrentDueAfterPayment}
              expectedTotalOutstandingDueAfterPayment={expectedTotalOutstandingDueAfterPayment}
              data={data}
            />
          )}
          {(values.payment_note === PARTIAL_PAYMENT || values.payment_note === PROMISE_TO_PAY) && (
            <PtpRecordPayment custLocation={custLocation} />
          )}
        </CardBody>
      </Card>
      <Card className="round-0 mt-4">
        <CardBody className="px-0">
          <ContactRecordPayment />
          {(values.payment_note === FULL_PAYMENT || values.payment_note === PARTIAL_PAYMENT) && (
            <ReceiptRecordPayment />
          )}
          <div className="px-4 pb-2">
            <Input
              type="textarea"
              label={values.unable_pay_more ? t('Remarks & Feedback') : t('Remarks & Feedback (Optional)')}
              id="remarks"
              placeholder={t('Type remarks here')}
              maxLength={250}
            />
          </div>
        </CardBody>
      </Card>

      <div
        style={{
          position: 'fixed',
          right: 0,
          left: 0,
          bottom: 0,
          zIndex: 100,
          height: '80px',
          width: '100%',
          background: '#fff',
          padding: '15px 0',
        }}
      >
        <Container>
          <ButtonDiv className="d-flex justify-content-end">
            <Button className="mr-4" color="cancel" type="button" onClick={() => history.push('/')}>
              {t('Cancel')}
            </Button>
            <Button type="submit" color="primary" disabled={isSubmitting}>
              {t('Submit')}
            </Button>
          </ButtonDiv>
        </Container>
      </div>
      {otpModal}
    </>
  );
};

export default PaymentForm;

const ButtonDiv = styles.div`
  @media (max-width: 1650px) {
    margin-right: 150px;
  }
`;
