import React from 'react';

function RedCircle() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM9.8 7.7H4.2C3.815 7.7 3.5 7.385 3.5 7C3.5 6.615 3.815 6.3 4.2 6.3H9.8C10.185 6.3 10.5 6.615 10.5 7C10.5 7.385 10.185 7.7 9.8 7.7Z"
        fill="#E15C5C"
      />
      <path
        d="M4.2 7.69999H9.8C10.185 7.69999 10.5 7.38499 10.5 6.99999C10.5 6.61499 10.185 6.29999 9.8 6.29999H4.2C3.815 6.29999 3.5 6.61499 3.5 6.99999C3.5 7.38499 3.815 7.69999 4.2 7.69999Z"
        fill="white"
      />
    </svg>
  );
}

export default RedCircle;
