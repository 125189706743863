import React from 'react';

function PrintIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1698 6.08333H19.4981V1.72118C19.4981 1.3228 19.1752 1 18.7769 1H5.07564C4.67736 1 4.35446 1.3228 4.35446 1.72118V6.08333H1.68274C0.754927 6.08333 0 6.83816 0 7.76607V17.0188C0 17.9466 0.754927 18.7015 1.68274 18.7015H4.35446V22.2788C4.35446 22.6772 4.67736 23 5.07564 23H18.7768C19.1751 23 19.498 22.6772 19.498 22.2788V18.7015H22.1697C23.0975 18.7015 23.8525 17.9466 23.8525 17.0188V7.76607C23.8526 6.83826 23.0976 6.08333 22.1698 6.08333ZM5.79681 2.44235H18.0557V6.08333H5.79681V2.44235ZM18.0557 21.5576H5.79681V14.4032H18.0557V21.5576ZM22.4102 17.0188C22.4102 17.1491 22.3001 17.2592 22.1698 17.2592H19.4981V13.682C19.4981 13.2836 19.1752 12.9608 18.7769 12.9608H5.07564C4.67736 12.9608 4.35446 13.2836 4.35446 13.682V17.2592H1.68274C1.55245 17.2592 1.44235 17.1491 1.44235 17.0188V7.76607C1.44235 7.63568 1.55245 7.52568 1.68274 7.52568H5.07564H18.7768H22.1697C22.3 7.52568 22.4101 7.63568 22.4101 7.76607V17.0188H22.4102Z"
        fill="#6956BA"
      />
      <path
        d="M18.7768 11.2691C19.3433 11.2691 19.8026 10.8099 19.8026 10.2433C19.8026 9.6768 19.3433 9.21753 18.7768 9.21753C18.2102 9.21753 17.751 9.6768 17.751 10.2433C17.751 10.8099 18.2102 11.2691 18.7768 11.2691Z"
        fill="#6956BA"
      />
      <path
        d="M15.2071 15.8264H8.645C8.24672 15.8264 7.92383 16.1492 7.92383 16.5476C7.92383 16.946 8.24672 17.2688 8.645 17.2688H15.2071C15.6054 17.2688 15.9283 16.946 15.9283 16.5476C15.9283 16.1493 15.6055 15.8264 15.2071 15.8264Z"
        fill="#6956BA"
      />
      <path
        d="M15.2071 18.6919H8.645C8.24672 18.6919 7.92383 19.0147 7.92383 19.4131C7.92383 19.8114 8.24672 20.1342 8.645 20.1342H15.2071C15.6054 20.1342 15.9283 19.8114 15.9283 19.4131C15.9283 19.0147 15.6055 18.6919 15.2071 18.6919Z"
        fill="#6956BA"
      />
    </svg>
  );
}

export default PrintIcon;
