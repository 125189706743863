import React from 'react';

function GrayCircle() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 7C0 3.136 3.136 0 7 0C10.864 0 14 3.136 14 7C14 10.864 10.864 14 7 14C3.136 14 0 10.864 0 7Z"
        fill="#E4E4E4"
      />
    </svg>
  );
}

export default GrayCircle;
