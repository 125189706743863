import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/types';
import { fetchCollection, updateNotification } from 'redux/crud/action';
import { Container, Button } from 'reactstrap';
import styles from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Localization } from 'constant';
import { AppContext } from 'helpers';

SwiperCore.use([Autoplay]);

const NotificationSwiper = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((st: State) => st);
  const { crudState } = useSelector((state: any) => state);
  const {
    notificationLoaded,
    setNotificationLoaded,
    sensitiveNotificationLoaded,
    setSensitiveNotificationLoaded,
  } = useContext(AppContext);

  const [notifications, setNotifications] = useState(
    crudState?.notifications?.collections?.otherInfo?.notifications || [],
  );

  useEffect(() => {
    // fetch non-sensitive notifications if user is not logged in
    if (!notificationLoaded && !auth?.user?.id) {
      setNotificationLoaded(true);
      dispatch(
        fetchCollection('notifications', 'cms/non-sensitive-notifications', {
          filter: {
            client: Localization.clientName,
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    // fetch sensitive notifications when user is logged in
    if (!sensitiveNotificationLoaded && auth?.user?.id) {
      setSensitiveNotificationLoaded(true);
      dispatch(
        fetchCollection('notifications', 'cms/sensitive-notifications', {
          filter: {
            client: Localization.clientName,
            role: auth.user.role,
          },
        }),
      );
    }
  }, [auth?.user.id]);

  useEffect(() => {
    if (crudState?.notifications?.collections?.otherInfo?.notifications) {
      setNotifications(crudState?.notifications?.collections?.otherInfo?.notifications || []);
    }
  }, [crudState?.notifications?.collections?.otherInfo?.notifications]);

  function handleDelete(notificationToDelete) {
    const updatedNotifications = notifications.filter((notification) => {
      return notification != notificationToDelete;
    });

    setNotifications(updatedNotifications);

    dispatch(updateNotification('notifications', { notifications: updatedNotifications }));
  }

  const Div =
    notifications.length > 0
      ? styles.div`
        padding: 15px;
        position: absolute;
        width: 100%;
        height: auto;
        text-align: center;
        background: #7864c4;
        color: white;
        z-index: 10001;
        opacity: 0.9;
        `
      : styles.div``;

  if (notifications.length <= 0) {
    return null;
  }

  return (
    <Div>
      <Container fluid={true}>
        <div>
          <Swiper spaceBetween={50} slidesPerView={1} autoplay={{ delay: 7000 }} loop>
            {notifications.length > 0 &&
              notifications.map((notification, index) => (
                <SwiperSlide
                  key={notification.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    {index + 1}/{notifications.length}
                  </div>
                  <div style={{ paddingLeft: '15px', paddingRight: '15px', fontSize: '14px' }}>
                    {notification.message}
                  </div>
                  <CloseIcon
                    onClick={() => handleDelete(notification)}
                    style={{ cursor: 'pointer', position: 'relative', left: '-15px' }}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </Container>
    </Div>
  );
};

export default NotificationSwiper;
